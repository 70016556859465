<template>
  <b-card no-body>
    <b-card-header>
      <h3>Domicile List</h3>
    </b-card-header>

    <b-card-body>
      <div class="d-flex">
        <b-button
          v-b-modal.modal-center
          class="mr-2"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="onAddDomicile"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          Add Domicile
        </b-button>
      </div>
      <br />
      <div class="d-flex justify-content-between flex-wrap">
        <!-- sorting  -->
        <b-form-group
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
        </b-form-group>

        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <v-select
              label="label"
              placeholder="Select"
              v-model="statusNameFilter"
              :options="statusOptions"
            />
        </b-col>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-button
          class="mr-2"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="onFilterSearch"
        >
          <feather-icon icon="SearchIcon" class="mr-50" />
          Search
        </b-button>
        <b-button
          class="mr-2"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          @click="onResetSearch"
        >
         
          Reset
        </b-button>
        </b-col>
       

        <b-col md="3" sm="8" xs="6">
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="md"
            label-for="filterInput"
            class="mb-0"
          >
            <!-- <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          /> -->
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </div>
    </b-card-body>
    <br />
    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="history_list"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(action)="data">
        <b-button
          class="mt-1"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          title="Edit Domicile Data"
          @click="onEdit(data.item)"
          style="
            position: relative;
            padding: 8px;
            width: 31px;
            margin-top: 0px !important;
          "
          v-b-modal.modal-center
        >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
            title="Edit Domicile Data"
          />
        </b-button>
      </template>
    </b-table>

    <!--  -->

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- pagination -->
      <div>
        <!-- <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination> -->
      </div>
    </b-card-body>

    <b-modal
      size="lg"
      id="modal-center"
      centered
      title="Domicile Data"
      ok-only
      ok-title="Cancel"
      ref="modal-center"
    >
      <b-row>
        <b-col cols="8">
          <b-form-group
            label="Domicile"
            label-for="h-domicile"
            label-cols-md="3"
          >
            <v-select
              v-if="!isAddMode"
              v-model="domicile"
              :close-on-select="true"
              :options="domicileOptions"
              label="title"
              input-id="h-domicile"
              placeholder="Select Domicile"
              :clearable="false"
            />
            <b-form-input
              v-else
              v-model="domicile"
              id="h-domicile"
              placeholder="Enter Domicile Number"
            />
            
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8">
          <b-form-group
            label="Schedule Email"
            label-for="h-schedule-email"
            label-cols-md="3"
          >
            <b-form-input
              v-model="schedule_email"
              id="h-schedule_email"
              placeholder="Enter Schedule Email"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8">
          <b-form-group
            label="MMR Email"
            label-for="h-mmr-email"
            label-cols-md="3"
          >
            <b-form-input
              v-model="mmr_email"
              id="h-mmr_email"
              placeholder="Enter MMR Email"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8">
          <b-form-group
            label="IVMR Email"
            label-for="h-ivmr-email"
            label-cols-md="3"
          >
            <b-form-input
              v-model="ivmr_email"
              id="h-ivmr_email"
              placeholder="Enter IVMR Email"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="8">
          <b-form-group
            label="Status"
            label-for="h-status-name"
            label-cols-md="3"
          >
            <v-select
              label="label"
              placeholder="Select"
              v-model="statusName"
              :options="statusOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="8">
          <b-form-group label="" label-for="h-status-name" label-cols-md="3">
            <b-button variant="primary" @click="onSave">
              {{ domicile_id != "" ? "Update" : "Submit" }}
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>
            
<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormCheckboxGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormTimepicker,
  BFormDatepicker,
  BDropdownItem,
  BDropdown,
  BLink,
  BPagination,
  BFormSelect,
} from "bootstrap-vue";
import { required, min, confirmed } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { serverUri } from "@/config";
import router from "@/router";
import { getUserData } from "@/auth/utils";
import flatPickr from "vue-flatpickr-component";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import axios from "axios";
import http from "@/services/http"

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BFormCheckboxGroup,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    BInputGroup,
    BInputGroupAppend,
    BFormTimepicker,
    BFormDatepicker,
    flatPickr,
    DateRangePicker,
    vSelect,
    BDropdownItem,
    BDropdown,
    BLink,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      isAddMode: true, 
      checkbox: false,
      userId: getUserData().driver_id,
      companyId: getUserData().company_id,
      domicile: "",
      domicileOptions: [],
      domicile_id: "",
      schedule_email: "",
      mmr_email: "",
      ivmr_email: "",
      statusName: "",
      statusNameFilter: "",
      perPage: 25,
      pageOptions: [5, 10, 15, 25],
      option: [],
      statusOptions: [
        { label: "Active", title: 1 },
        { label: "In-Active", title: 0 },
      ],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      history_list: [],
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      filter: " ",
      fields: [
        {
          key: "domicile",
          label: "Domicile",
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "schedule_email",
          label: "Schedule Email",
          sortable: true,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "mmr_email",
          label: "MMR Email",
          sortable: true,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "ivmr_email",
          label: "IVMR Email",
          sortable: true,
          formatter: (value) => {
            return value;
          },
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
          formatter: (value) => {
            return value == 1 ? "Active" : "In-Active";
          },
        },
        {
          key: "action",
        },
      ],
    };
  },
  mounted() {},
  computed: {},
  props: {},
  methods: {
    onEdit(details) {
      console.log(details);
      this.isAddMode = false; // Set to false for 'edit' mode
      this.domicile_id = details.id;
      this.domicile = details.domicile;
      this.schedule_email = details.schedule_email;
      this.mmr_email = details.mmr_email;
      this.ivmr_email = details.ivmr_email;
      this.statusName = details.status == 1 ? "Active" : "In-Active";

      this.$refs["modal-center"].show();
    },

    getDomicileData() {
      http.get(`${serverUri}/utilities/allDomicileData`,
          {headers: {
              'Authorization': `Bearer ${getUserData().accessToken}`,
              'Content-Type': 'application/json'
          }}).then((res) => {
        this.history_list = res.data;
      });
    },

    onSave() {
      if (this.domicile_id) {
        axios
          .post(`${serverUri}/utilities/updateDomicileData`, {
            id: this.domicile_id,
            company_id: this.companyId,
            domicile:
              this.domicile.name == undefined
                ? this.domicile
                : this.domicile.name,
            schedule_email: this.schedule_email,
            mmr_email: this.mmr_email,
            ivmr_email: this.ivmr_email,
            status:
              this.statusName.title == undefined
                ? this.statusName
                : this.statusName.title,
          })
          .then((res) => {
            if (res.data.status) {
              this.showToast(
                "success",
                "Success",
                "The Domicile data has been saved."
              );
              this.$refs["modal-center"].hide();

              this.domicile = "";
              this.schedule_email = "";
              this.mmr_email = "";
              this.ivmr_email = "";
              this.statusName = "";

              this.getDomicileData();
            } else {
              this.showToast("warning", "Failure", res.data.message);
            }
          });
      } else {
        axios
          .post(`${serverUri}/utilities/insertDomicileData`, {
            company_id: this.companyId,
            domicile: this.domicile.name == undefined
                ? this.domicile
                : this.domicile.name,
            schedule_email: this.schedule_email,
            mmr_email: this.mmr_email,
            ivmr_email: this.ivmr_email,
            status:
              this.statusName.title == undefined || this.statusName.title == ""
                ? this.statusName
                : this.statusName.title,
          })
          .then((res) => {
            if (res.data.status) {
              this.showToast(
                "success",
                "Success",
                "The Domicile data has been saved."
              );
              this.$refs["modal-center"].hide();

              this.domicile = "";
              this.schedule_email = "";
              this.mmr_email = "";
              this.ivmr_email = "";
              this.statusName = "";

              this.getDomicileData();
            } else {
              this.showToast("warning", "Failure", res.data.message);
            }
          });
      }
    },

    onAddDomicile() {
      this.isAddMode = true; // Set to true for 'add' mode
      this.domicile_id = "";
      this.domicile = "";
      this.schedule_email = "";
      this.mmr_email = "";
      this.ivmr_email = "";
      this.statusName = "";
    },

    showToast(variant, title, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: text,
          variant,
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    onFilterSearch()
    {
      if(this.statusNameFilter != undefined)
      {
        axios.get(`${serverUri}/utilities/allDomicileData?status=${this.statusNameFilter.title}`).then((res) => {
        this.history_list = res.data;
      });

      } else {
        this.statusNameFilter = "";
        this.getDomicileData();
      }
    },
    onResetSearch () 
    {
      this.statusNameFilter = "";
      this.getDomicileData();
    }
  },
  created() {
    this.getDomicileData();

    // get domiciles
    axios.get(`${serverUri}/utilities/allFleetDomicileData`, {
        company: getUserData().company_id,
      })
      .then((res) => {
        const response = res.data.filter((item) => item.domicile?.length > 0);

        console.log(this.history_list);

        response.map((item) => {
          this.domicileOptions.push({
            title: item.domicile,
            name: item.domicile,
          });
        });
        // this.tractorsOption.push({ title: 'OFF' })
      });
  },
};
</script>
            
            <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.edit-header {
  display: flex;
  align-items: center;
}
</style><style>
.permission {
  margin: 10px 0;
}

.permission div {
  margin: 2px 0;
}
button.btn.btn-danger {
  margin-left: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #f22323;
}

input:focus + .slider {
  box-shadow: 0 0 1px #101010;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* .dropdown {
  top: -20px;
} */
</style>
            